// SASS
require('../scss/style.scss');

// JQuery
import $ from 'jquery';
import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

// JS
import 'lightgallery';
import 'slick-carousel';
import whatInput from 'what-input';

//foundation
import './foundation-modules.js';
import './kmlMaps.js';

// Show Old IE Notice
if(!!window.ActiveXObject) {
    $("#ieNotice").removeClass("hide");
}

// Mobile Nav
$('.menu-icon a').click(function() {
    $('header nav').toggleClass("open");
    $(this).toggleClass("open");
    return false;
});

(function(context){
    let breakPoint = 1024;
    $(context+" nav ul>li>a").click(function(event, force){
        if (whatInput.ask() === 'keyboard' || whatInput.ask() === 'touch' || $(window).width() < breakPoint) {
            if($(this).parent().find("ul").length > 0 && (($(window).width() >= breakPoint && $(this).parent().hasClass('level-1')) || $(window).width() < breakPoint)) {
                if (!$(this).parent().hasClass('open')) {
                    $(this).closest("ul").find('.open').removeClass('open');
                    $(this).parent().addClass('open');
                } else {
                    $(this).parent().removeClass('open');
                }
                event.preventDefault();
            } else if (force) {
                window.location = $(this).attr('href');
            }
            event.stopPropagation();
        }
    }).keydown(function(event){
        if(whatInput.ask() === 'keyboard' && event.keyCode === 32){
            event.preventDefault();
            $(this).trigger('click', true);
        }
    });

    $(context+" .menu-icon a").keydown(function(event){
        if(whatInput.ask() === 'keyboard' && event.keyCode === 32){
            event.preventDefault();
            $(this).trigger('click', true);
        }
    });

    $("body").click(function(event){
        $(context).find('.open').removeClass('open');
    });

    if($(window).width() < breakPoint) {
        $(context+" nav ul ul .active").each(function(){
            $(this).parent().parent().addClass('open');
        });
    }

})(("header "));

if($('.slider').length) {
    $('.slider').slick({
        prevArrow:'<button type="button" data-role="none" class="icon-left-open-big slick-prev" aria-label="Previous" tabindex="0" role="button"></button>',
        nextArrow:'<button type="button" data-role="none" class="icon-right-open-big slick-next" aria-label="Next" tabindex="0" role="button"></button>',
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: false,
        dots: true,
        fade: true
    });
}

// Light Gallery
if($(".lg-images").length) {
    $(".lg-images").lightGallery({
        selector: 'a', loop: false, download: false
    });
}

$(".overview h2, .content-blocks h2, .content-blocks h3").each(function(el){
    var $t = $(this);
    if($t.find("b").length == 0) {
        if($t.find("a").length) {
            $t = $t.find("a");
        }
        $t.html($("<b>"+$t.text()+"</b>"));
    }
});

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

(function() {
    var $headers = $("h2 b, h3 b").toArray();
    shuffleArray($headers);
    $headers = $headers.slice(0, Math.round($headers.length/2));
    $($headers).addClass('alt');
})();

$('.picker > a').click(function() {
    $(this).parent().find("ul").toggleClass("open");
    return false;
});

$(document).foundation();

setTimeout(function () {

    var hidden = localStorage.getItem('warningHidden');

    var diff = new Date().getTime() - hidden;
    var hh = Math.floor(diff / 1000 / 60 / 24);

    if (hh >= 14 || isNaN(diff)) {
        $('#cookie-warning').addClass("show");
    }

    $('#cookie-warning .close-button').click(function () {
        $('#cookie-warning').removeClass("show");
        localStorage.setItem('warningHidden', new Date().getTime());
    });

}, 2000);

$(".play[data-content]").click(function(){

    let $video = $($(this).data('content'));
    $video.attr("src", $video.attr("src").replace('?feature=oembed', '')+"?autoplay=1&rel=0");

    $(this).find("object").replaceWith($video);
    $(this).addClass('playing');

    return false;
});

$('.rental-info-container').each(function () {
	var thisContainer = $(this);
	$.get('/rental-info.php?id='+$(this).data('id')+'&lang='+$(this).data('lang'), function (content) {
		thisContainer.html(content);
	})
});
