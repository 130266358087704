// JQuery
import $ from 'jquery';
import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

let pathLine = null;
let map = null;
let bounds = null;

let $kml = $('.view-kml');

if($kml.length > 0) {

    $kml.on('tap', function (e) {
        e.preventDefault();
    }).on('click', function (e) {

        e.preventDefault();

        $('#kml-viewer').foundation('open');
        google.maps.event.trigger(map, 'resize');

        map.fitBounds(bounds);
        map.panToBounds(bounds);

        if(map.getZoom() >= 14) {
            map.setZoom(14);
        }

        let url = $(this).attr('href');

        // CORS currently disabled. Need to use same domain
        url = "/"+url.substr(url.indexOf('/', 8) + 1);

        $.get(url, function (xmlDoc) {

            let i;
            let coordinates = $(xmlDoc).find('coordinates')[0].textContent.trim().split(' ');

            for (i in coordinates) {
                coordinates[i] = coordinates[i].split(',');
            }

            geoCoords = [];

            for (i = 0; i < coordinates.length; i++) {
                geoCoords[i] = new google.maps.LatLng(coordinates[i][1], coordinates[i][0]);
            }

            console.info(geoCoords);

            pathLine.setPath(geoCoords);

            bounds = new google.maps.LatLngBounds();
            bounds.extend(new google.maps.LatLng(43.454, -0.920));

            for (i = 0; i < geoCoords.length - 1; i++) {
                bounds.extend(geoCoords[i]);
            }

            map.fitBounds(bounds);
            map.panToBounds(bounds);

            if(map.getZoom() >= 16) {
                map.setZoom(16);
            }

        });

        return false;
    });

    $.getScript( "https://maps.googleapis.com/maps/api/js?key=AIzaSyBDZQeqQYI6jWAOvC_7BNQmUOY3KY_kUro", function() {

        map = new google.maps.Map(document.getElementById("map"), {
            center: new google.maps.LatLng(43.452568, -0.920212),
            zoom: 14,
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            fullscreenControl: false
        });

        new google.maps.Marker({
            position: new google.maps.LatLng(43.453, -0.9208),
            map: map,
            title: "Esperbasque",
            icon: {
                url: "/img/map/mini-logo.png",
                scaledSize: new google.maps.Size(46, 39)
            }
        });

        pathLine = new google.maps.Polyline({
            path: [new google.maps.LatLng(43.454, -0.920)],
            strokeColor: "#6ed400",
            strokeOpacity: 0.85,
            strokeWeight: 3,
            map: map
        });

        bounds = new google.maps.LatLngBounds();
        bounds.extend(new google.maps.LatLng(43.454, -0.920));

    });
}
